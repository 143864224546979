import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/dict',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/dict/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'crm/dict',
    method: 'put',
    data
  })
}

/**
 *  type=auditCustRejectReason 客户、代理审核拒绝原因 type=depCommRejectReason  出金、出佣审核拒绝原因
 * @param data
 */
export function getDict(data) {
  return request({
    url: 'crm/dict',
    method: 'get',
    params: data
  })
}
